<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">民族分布</div>
    <div class="idx-middle-2" id="dgwData"></div>
    <!--  -->
    <!-- <div class="idx-middle-3">
      <span>{{ getdatavalue(96) }}</span>
      <span>已退休人数</span>
    </div> -->
  </div>
</template>

<script>
import { listCountData ,getBranchDetail} from "@/api/dtdata";
export default {
  components: {},
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      queryParams: this.$route.query.id,
    };
  },
  computed: {},
  created() {
    const id = this.$route.query && this.$route.query.id;
    getBranchDetail("szdt_national_type",id).then( res => {
      this.educationGroup = res.data;
      for (let i = 0; i < res.data.length; i++) {
        this.ydata[i] = {};
        this.ydata[i].name = res.data[i].label;
        this.ydata[i].value = res.data[i].value;
        this.xdata[i] = res.data[i].label;
      }
      this.shijianFs();
    })
    // this.getListInfo();
  },

  methods: {
    getListInfo() {
      listCountData(this.queryParams).then((res) => {
        for (let i = 0; i < res.data.national.length; i++) {
          this.ydata[i] = {};
          this.ydata[i].name = res.data.national[i].label;
          this.ydata[i].value = parseInt(res.data.national[i].value);
          this.xdata[i] = res.data.national[i].label;
        }
        this.shijianFs();
      });
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
    // 事件发生地
    shijianFs() {
      // 判断pc iPad
      if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = [
          "#0567E2",
          "#01689B",
          "#FFD901",
          "#EB7BDA",
          "#68DBB2",
          "#4F6EDD",
          "#BA0C00",
        ];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "horizontal",
            left: "0%",
            top: "80%",
            itemWidth: 8,
            itemHeight: 8,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 10,
              padding: 0,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata, //["东泰社区两新党委", "东泰社区政法党支部", "东泰社区党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "25%",
              center: ["45%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "10",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      } else {
        let myChart = this.$echarts.init(document.getElementById("dgwData"));
        let color = [
          "#0567E2",
          "#01689B",
          "#FFD901",
          "#EB7BDA",
          "#68DBB2",
          "#4F6EDD",
          "#BA0C00",
        ];
        let app = {};
        //   let option = null;
        let option = {
          title: {
            text: "",
            subtext: "",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{a} <br/>{b} : {c} ({d}%)",
          },
          color: color,
          legend: {
            // 图例列表的布局朝向。 'horizontal'  'vertical'
            orient: "horizontal",
            left: "2%",
            top: "80%",
            itemWidth: 12,
            itemHeight: 12,
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 14,
              padding: 8,
            },
            formatter: function (name) {
              return "" + name;
            },
            data: this.xdata, //["东泰社区两新党委", "东泰社区政法党支部", "东泰社区党支部"],
          },
          series: [
            {
              name: "数字东泰",
              type: "pie",
              radius: "30%",
              center: ["45%", "40%"],
              data: this.ydata,
              itemStyle: {
                normal: {
                  label: {
                    show: true,
                    formatter: "{b} \n {c}人",
                    // formatter: "{b} : {c} ({d}%)",
                    fontSize: "12",
                    fontWeight: "bold",
                  },
                  labelLine: {
                    show: true,
                  },
                },
              },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
      }
    },
  },
  mounted() {
    this.shijianFs();
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 51vh;
  overflow: hidden;
  position: relative;
  //   border: 1px solid tan;
  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    .dqi-icon {
      vertical-align: middle;
      padding-right: 1vw;
    }
  }
  .idx-middle-2 {
    width: 100%;
    height: 39vh;
    // border: 1px solid tan;
  }
  .idx-middle-3 {
    width: 23%;
    height: 8vh;
    line-height: 3vh;
    background: #01689b;
    position: absolute;
    top: 20vh;
    right: 3vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    span {
      font-size: 0.9vw;
      &:first-child {
        font-size: 1.2vw;
        font-family: hy;
        font-weight: bold;
        font-style: italic;
        color: #ffffff;
      }
    }
  }
}
</style>
