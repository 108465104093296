<template>
  <div>
    <div class="container">
      <!-- 背景 -->
      <img class="back-ground-image" src="@/assets/image/shuju-tj.png" alt="" />
      <!-- 顶部 -->
      <div class="header">数字东泰</div>
      <!-- 返回 刷新 -->
      <rightNav></rightNav>
      <!-- 时间 -->
      <div class="time-box">
        <Date></Date>
      </div>
      <!-- top -->
      <div class="renkou-top">
        <div class="renkou-top-L">
          <!-- 人数概况 -->
          <DangzhibuQk></DangzhibuQk>
        </div>
        <div class="renkou-top-M">
          <!-- 党务建设 -->
          <DangwuJs></DangwuJs>
        </div>
        <div class="renkou-top-R">
          <!-- 民族分布 -->
          <DgwData></DgwData>
        </div>
      </div>
      <!-- bottom -->
      <div class="renkou-bottom">
        <div class="renkou-bottom-L">
          <!-- 党员学历分布 -->
          <DangyuanXl></DangyuanXl>
        </div>
        <div class="renkou-bottom-R">
          <!-- 党员年龄分布 -->
          <DangyuanAge></DangyuanAge>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Demo from "@/views/dataStatistics/components/demo.vue";
import Date from "@/views/commonComponents/date.vue";
import DangzhibuQk from "@/views/dataStatistics/components/dangzhibuQk.vue";
import DangyuanXl from "@/views/dataStatistics/components/dangyuanXl.vue";
import DangyuanAge from "@/views/dataStatistics/components/dangyuanAge.vue";
import DgwData from "@/views/dataStatistics/components/dgwData.vue";
import DangwuJs from "@/views/dataStatistics/components/dangwuJs.vue";
import rightNav from "@/views/commonComponents/rightNav.vue";

export default {
  components: {
    Demo,
    Date,
    DangzhibuQk,
    DangyuanXl,
    DangyuanAge,
    DgwData,
    DangwuJs,
    rightNav,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  mounted() {},
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  height: auto;
  overflow: hidden;
  
  .back-ground-image {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  // 顶部
  .header {
    width: 100%;
    height: 10vh;
    line-height: 10vh;
    letter-spacing: 4vw;
    padding-left: 1vw;
    // background: linear-gradient(to top, #c0d9ff, #fff);
    background: linear-gradient(to top, #c0d9ff, #fff);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3vw;
    text-align: center;
    font-family: Microsoft YaHei;
    font-weight: bold;
    // color: #FFFFFF;
    text-shadow: 0px 10px 7px rgba(0, 0, 0, 0.17);
  }
  .time-box {
    width: 25vw;
    height: 7vh;
    // border: 1px solid teal;
    position: fixed;
    top: 7vh;
    right: 3vw;
    z-index: 99;
  }
  // top
  .renkou-top {
    width: 100vw;
    height: 51vh;
    // border: 1px solid tomato;
    .renkou-top-L {
      width: 22.5vw;
      height: 51vh;
      // border: 1px solid tan;
      margin-left: 2vw;
      float: left;
    }
    .renkou-top-M {
      width: 41.5vw;
      height: 45vh;
      // border: 1px solid tan;
      margin-left: 4.3vw;
      margin-top: 6vh;
      float: left;
    }
    .renkou-top-R {
      width: 25.9vw;
      height: 45vh;
      float: left;
      margin-left: 1.9vw;
      // border: 1px solid tan;
      margin-top: 6vh;
    }
  }
  //   bottom
  .renkou-bottom {
    width: 96vw;
    height: 31vh;
    // border: 1px solid tomato;
    margin: 0 auto;
    margin-top: 5.7vh;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .renkou-bottom-L {
      width: 47.5%;
      height: 31vh;
      //   border: 1px solid tomato;
      position: relative;
    }
    .renkou-bottom-R {
      width: 46.5%;
      height: 31vh;
      //   border: 1px solid tomato;
      position: relative;
    }
  }
}
</style>
