<template>
  <div class="idx-middle-box3">
    <div class="idx-middle-1">党务建设</div>
    <div class="idx-middle-2">
      <div class="idx-Lbox-2">
        <img src="@/assets/image/idx-icon-yh.png" />
        <span class="idx-dy-sa1"> 党员 </span>
        <span class="idx-dy-s1"> {{ total }} </span>名
        <span class="idx-dy-sb1"v-for="(item,index) of nativeGroup" :key="index" >{{ item.label }} {{ item.value }} 人</span>
        <!-- 党员名册 -->
        <span class="dwjs-btn" @click="back">
          <img class="dwjs-btn-1" src="@/assets/image/dwjs-mce.png" />
          <img class="dwjs-btn-2" src="@/assets/image/dwjs-name.png" />
          <span class="dwjs-btn-3">党员名册</span>
        </span>
        <!-- <span class="idx-dy-s3"><div class="gl-sss"></div></span>
        <img src="@/assets/image/idx-icon-dy3.png" />
        <span class="idx-dy-sa1"> 组织 </span>
        <span class="idx-dy-s2"> {{ getdatavalue(87) }} </span>个 -->
      </div>
    </div>
    <div class="idx-middle-3">
      <!-- <span>东莞户籍党员 {{ getdatavalue(88) }} 人</span>
      <span>市外户籍 {{ getdatavalue(89) }} 人</span> -->
    </div>
    <div class="idx-middle-4">
      <!-- <div class="idx-middle-4L" id="dwjs"></div> -->
      <div class="idx-middle-4R">
        <div class="idx-middle-4Rbox" v-for="(item,index) of partyGroup" :key="index">
          <span>{{ item.value }}</span>
          <span>{{item.label}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listCountData, listCountDataDetail, getBranchDetail } from "@/api/dtdata";
export default {
  data() {
    return {
      ydata: [],
      xdata: [],
      dataOptions: [],
      dataOptionsTop: [],
      queryParams: this.$route.query.id,
      partyGroup: undefined,
      nativeGroup: undefined,
      id:this.$route.query.id,
      total: undefined
    };

  },
  components: {},
  computed: {},
  created() {
    const id = this.$route.query | this.$route.query.id;
    getBranchDetail("party_group",id).then( res => {
      this.partyGroup = res.data;
    })
    getBranchDetail("native_group",id).then( res => {
      this.nativeGroup = res.data;
      let total = 0;
      res.data.forEach( v => {
        total += v.value;
      })
      this.total = total;
    })
    this.getListInfo();
  },

  methods: {
    getListInfo() {
      // 获取党组织信息
      listCountDataDetail(this.queryParams).then((res) => {
        this.dataOptions = res.data;
      });
      listCountData(this.queryParams).then((res) => {
        console.log("获取党组织信息", res.data.nativePlace);
        this.dataOptionsTop = res.data.nativePlace;
      });
    },
    back() {
      this.$router.go(-1);
    },
    getdatavalue(id) {
      return getvalue(this.dataOptions, id);
    },
  },
  mounted() {
  },
};
</script>

<style scoped lang="scss">
.idx-middle-box3 {
  width: 100%;
  height: 45vh;
  overflow: hidden;

  .idx-middle-1 {
    width: 100%;
    height: 6vh;
    line-height: 6vh;
    text-align: center;
    font-size: 0.9vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffd901;
    .dqi-icon {
      vertical-align: middle;
      padding-right: 1vw;
    }
  }
  .idx-middle-2 {
    width: 100%;
    height: 7vh;
    // overflow: hidden;
    // border: 1px solid turquoise;
    .idx-Lbox-2 {
      width: 97%;
      height: 4vh;
      line-height: 4vh;
      font-size: 0.9vw;
      font-family: Microsoft YaHei;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 6vh;
      display: inline-block;
      padding-left: 6vw;
      img {
        width: 1.1vw;
        height: 2vh;
      }
      .idx-dy-s1 {
        font-size: 1.3vw;
        font-family: hy;
        font-style: italic;
        font-weight: bold;
        color: #f9b129;
      }
      .idx-dy-sb1 {
        font-size: 0.85vw;
        color: #cacaca;
        padding: 0 1vw 0 1vw;
      }
      .dwjs-btn {
        width: 6vw;
        height: 4vh;
        // border: 1px solid teal;
        display: inline-block;
        position: relative;
        top: 0.5vh;
        margin-left: 1vw;
        cursor: pointer;
        .dwjs-btn-1 {
          width: 6vw;
          height: 4.5vh;
          position: absolute;
          top: 0.25vw;
          left: 0vw;
        }
        .dwjs-btn-2 {
          width: 1.8vw;
          height: 1.8vw;
          position: absolute;
          top: 1vh;
        }
        .dwjs-btn-3 {
          font-size: 0.8vw;
          position: absolute;
          left: 2vw;
          top: 1vh;
        }
      }
      span.idx-dy-sa1 {
        padding: 0 0.1vw;
      }
      .idx-dy-s2 {
        font-size: 1.3vw;
        font-family: hy;
        font-style: italic;
        font-weight: bold;
        color: #e54f2c;
      }
      .idx-dy-s3 {
        padding: 1vh 7vw 0 2vw;
        box-sizing: border-box;
        position: relative;
        .gl-sss {
          position: absolute;
          width: 1px;
          height: 2.2vh;
          background: #ffffff;
          top: 1.25vh;
          box-sizing: border-box;
          display: inline-block;
        }
      }
    }
  }
  .idx-middle-3 {
    width: 100%;
    height: 5vh;
    line-height: 5vh;
    overflow: hidden;
    // border: 1px solid turquoise;
    font-size: 0.8vw;
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #cacaca;
    padding-left: 2vw;
    span {
      &:first-child {
        padding-right: 1vw;
      }
    }
  }
  .idx-middle-4 {
    width: 100%;
    height: 27vh;
    .idx-middle-4L {
      width: 40%;
      height: 27vh;
      float: left;
    }
    .idx-middle-4R {
      width: 100%;
      height: 8vh;
      float: left;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      margin-top: 9vh;
      .idx-middle-4Rbox {
        width: 15%;
        height: 8vh;
        line-height: 3vh;
        background: #01689b;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        color: #fff;
        span {
          font-size: 0.9vw;
          &:first-child {
            font-size: 1.2vw;
            font-family: hy;
            font-weight: bold;
            font-style: italic;
            color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
